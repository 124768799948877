<template>
    <div class="user-wrap w1200">
        <div class="user-box">
            <img :src="rImg(data.head_portrait)" :onerror="txImg" class="tx" alt="" />
            <p class="u-name">{{ data.remarkname || data.name }}</p>
            <p class="des">ID: {{data.id}}</p>
            <p class="des" v-if="data.institutional">机构: {{data.institutional}}</p>
            <div style="margin: 20px 0" v-if="labels.shanchang.length || labels.jingli.length">
                <div class="rz-box">擅长：<span :key="i" class="rz" v-for="(m,i) in labels.shanchang"><i class="iconfont icon-renzheng"></i>{{m.name}}</span></div>
                <div class="rz-box">经历：<span :key="i" class="rz" v-for="(m,i) in labels.jingli"><i class="iconfont icon-renzheng"></i>{{m.name}}</span></div>
            </div>
            <p class="des">{{ data.sex }} {{ data.age }}岁{{ data.city ? ' ，所在地：'+ data.city: ''}}</p>
            <p class="des">个人简介：{{data.self_slogan || 'TA很神秘~'}}</p>
            <!-- <ul class="gz-box">
        <li class="li">
          <p class="num" v-html="returnNum(data.friend)"></p>
          <p class="name">好友</p>
        </li>
        <li class="li">
          <p class="num" v-html="returnNum(data.following)"></p>
          <p class="name">关注</p>
        </li>
        <li class="li">
          <p class="num" v-html="returnNum(data.attention)"></p>
          <p class="name">粉丝</p>
        </li>
      </ul> -->
            <el-button style="margin: 50px 0" round class="app-btn" @click="openApp()">私聊</el-button>
        </div>
        <!-- <div class="art-wrap">
      <el-menu :defaultActive="navIndex" mode="horizontal" class="el-menu-vertical-demo" active-text-color="#fd5621" :router="true">
        <el-menu-item :key="m.id" :index="m.id" v-for="m in navList" :route="{ path: '/user', query: { cls: m.cls, uuid: uid } }">
          <span class="menu-name" slot="title">{{m.name}}</span>
        </el-menu-item>
      </el-menu>
      <div class="content"><router-view></router-view></div>
    </div> -->
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {},
        data() {
            return {
                uid: "",
                navIndex: "1",
                data: {},
                labels: {
                    shanchang: [],
                    jingli: []
                }, //兴趣标签
                navList: [{
                    id: "1",
                    name: "贴",
                    cls: 1,
                }, {
                    id: "2",
                    name: "经验",
                    cls: 2,
                }, {
                    id: "3",
                    name: "故事",
                    cls: 3,
                }],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.uid = this.$route.query.uuid;
            this.checkPath(this.$route);
            //   if (!this.s_getUser) {
            //     this._home();
            //   }
            this.getData();
            this.setMeta("用户详情", 1);
            this.getLabels();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            checkPath(t) {
                let q = t.query;
                if (q && q.cls) {
                    let arr = this.navList;
                    for (let i = 0, len = arr.length; i < len; i++) {
                        if (q.cls == arr[i].cls) {
                            this.navIndex = arr[i].id;
                            break;
                        }
                    }
                }
            },
            async getLabels() {
                let {
                    code,
                    data
                } = this.$y_ajax('api/Nihao/userLabel', {
                    user_id: this.uid,
                })
                if (code == 200) {
                    this.labels = ret.data;
                }
            },
            async getData() {
                let {
                    result
                } = await this.$y_ajax("api/user/info", {
                    user_id: this.uid,
                    to_user: this.$y_getKey("userid") || this.uid,
                    longitude: 1,
                    latitude: 1,
                });
                this.data = result;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
            // 监听
            $route(to, from) {
                // 路径判断
                this.checkPath(to);
            },
        },
    };
</script>

<style lang="less" scoped>
    .user-wrap {
        // display: flex;

        .user-box {
            padding-top: 50px;
            text-align: center;

            // margin-right: 30px;
            // width: 20vw;
            .tx {
                width: 100px;
                height: 100px;
            }

            .u-name {
                font-size: 20px;
                font-weight: bold;
                margin: 20px 0 10px;
            }

            .des {
                font-size: 12px;
                color: #999;
                margin-bottom: 10px;
            }

            .rz-box {
                margin-bottom: 7px;
                display: flex;
                flex-wrap: wrap;

                .rz {
                    color: #fd5621;
                    background: #FEEDE8;
                    font-size: 0.5rem;
                    border-radius: 3px;
                    margin-right: 5px;
                    padding: 3px;
                    margin-top: 0;
                    margin-bottom: 5px;

                    .iconfont {
                        margin-right: 3px;
                        font-size: 0.5rem;
                    }
                }
            }

            .gz-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0;

                .li {
                    margin: 0 30px;
                }

                .num {
                    font-size: 20px;
                    margin-bottom: 5px;
                }

                .name {
                    color: #555;
                }
            }

            .app-btn {
                background: #fd5621;
                border: 0;
                color: #fff;
                padding: 12px 50px;
            }
        }

        .art-wrap {
            flex: 1;

            .menu-name {
                width: 100px;
                display: inline-block;
                text-align: center;
            }
        }
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .user-wrap {
            display: block;

            .art-wrap .menu-name {
                width: auto;
            }
        }

        /deep/.el-menu {
            display: flex;
            justify-content: center;
        }
    }
</style>